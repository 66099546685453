import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"

type ImgStatic = {
	allFile: {
		nodes: {
			relativeDirectory: string
			name: string
			childImageSharp: {
				gatsbyImageData: IGatsbyImageData
			}
		}[]
	}
}

type ImgProps = {
	folder?: string
	name: string
	width?: string
}

const Image = (props: ImgProps) => {
	const data = useStaticQuery<ImgStatic>(graphql`
		{
			allFile(filter: {sourceInstanceName: {eq: "img"}}) {
				nodes {
					relativeDirectory
					name
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	`)
	const img = data.allFile.nodes.find(e => ((!props.folder && !e.relativeDirectory) || e.relativeDirectory == props.folder) && e.name == props.name)
	if(img) return <GatsbyImage image={getImage(img)} alt={img.name} style={{width: props.width}} />
	else return <div>Not Found</div>
}

export default Image

export const Gi = (props: ImgProps) => {
	return <Image folder="gi-chara-icon" {...props} />
}

export const GiArtW6em = (props: ImgProps) => {
	return <Image folder="gi-artifact-icon" width="6em" {...props} />
}
